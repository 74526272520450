@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --theme-color: #1f2937;
  --theme-color-two: #6b7280;
  --theme-bg-two: #3a86ff;
  --theme-bg: #513aff;
  --theme-bg-hover: #3b24eb;
  --theme-bg-secondary: #111827;
  --bootstrap-icon: 'bootstrap-icons';
  --line-icon: 'Line Awesome Free';
}

body {
  background: #eff2f5;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: var(--theme-color-two);
  font-weight: 400;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.angular-editor-textarea ul,
.angular-editor-textarea li,
.comments-container ul,
.comments-container li {
  margin: revert;
  padding: revert;
  list-style-type: revert;
}
input,
input:focus,
input:active,
select:focus,
select:active,
option:focus,
option:active,
textarea:focus,
textarea:active,
button,
button:focus,
button:active,
.btn:focus,
.btn:active,
.btn-primary:focus,
.btn-primary:active,
.form-control:focus,
.form-control:active {
  outline: none;
  box-shadow: none;
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  margin: 0px;
  padding: 0px;
  line-height: 1.3;
  font-weight: 700;
  color: var(--theme-color);
}
h1,
.h1 {
  font-size: 42px;
}
h2,
.h2 {
  font-size: 36px;
}
h3,
.h3 {
  font-size: 30px;
}
h4,
.h4 {
  font-size: 24px;
}
h5,
.h5 {
  font-size: 18px;
}
a,
a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  color: var(--theme-color);
}
figure {
  margin: 0;
}
img {
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
}
p a {
  color: var(--theme-color);
  font-style: italic;
  text-decoration: underline;
}
p {
  line-height: 25px;
  margin-bottom: 15px;
}
p strong {
  font-weight: 500;
}
p span {
  display: block;
}
.form-control::-webkit-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-control:-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
.section-title {
  margin-bottom: 8px;
  text-align: left;
}
.section-title .h1 {
  position: relative;
  padding: 0;
  font-size: 20px;
  border-bottom: 2px solid #eee;
  line-height: 40px;
}
.section-title .h1:after {
  width: 80px;
  height: 2px;
  background: #222;
  content: '';
  left: 0;
  bottom: -2px;
  position: absolute;
}
a.readmore,
.readmore {
  font-weight: 500;
  background: var(--theme-bg);
  padding: 0 25px;
  border-radius: 4px;
  display: inline-block;
  line-height: 44px;
  color: #fff;
  border: none;
  cursor: pointer;
}
.readmore:hover,
.readmore:focus,
.readmore:active {
  background: var(--theme-bg-hover);
  color: #fff;
}
.readmore-two {
  background: var(--theme-bg-secondary);
}
.readmore-three {
  background: #e4f2ff;
  color: var(--theme-bg);
}

section,
footer,
header {
  float: left;
  width: 100%;
  padding: 50px 0;
  overflow: hidden;
  z-index: 2;
  position: relative;
}
.white-bg {
  background: #fff;
}
.gray-bg {
  background: #f1f1f1;
}
.no-margins {
  margin-left: 0;
  margin-right: 0;
}
.form-group {
  margin-bottom: 1.5rem;
}
.form-group > label {
  margin-bottom: 12px;
  display: block;
  color: var(--theme-color);
  line-height: 1;
}
.form-group label strong {
  font-weight: 500;
}
.form-control {
  height: 46px;
  border-radius: 4px;
  background: #f3f4f6;
  color: #6b7280;
  border-color: #f3f4f6;
  font-size: 15px;
  padding: 0 0 0 15px;
}
.form-group .file,
.form-group .file-two {
  height: 46px;
  border-radius: 4px;
  background: #f3f4f6;
  color: #6b7280;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d1d5db;
}
.form-group .file > input,
.form-group .file-two > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0px;
  opacity: 0;
}
.form-group .file span a {
  color: #3a86ff;
  text-decoration: underline;
}
.form-group .file-two {
  justify-content: start;
  height: auto;
  padding: 15px;
}
.form-group .file-two i {
  width: 46px;
  height: 46px;
  margin-right: 10px;
  border-radius: 4px;
  background: #d1d5db;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
}
.form-group .file-two span {
  width: 275px;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}
.form-control:focus {
  background: #f3f4f6;
  border-color: var(--theme-bg);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  margin: 0;
  padding-top: 0.25px;
  background: #fff;
}
textarea.form-control {
  height: 80px;
  resize: none;
  padding-top: 15px;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn,
button:disabled,
button.normal-btns:disabled {
  background: #e7e7e7;
  color: #787878;
  cursor: not-allowed;
}

.two_chk > label {
  width: 50%;
}

.pswd_vis {
  position: relative;
  z-index: 1;
  background: #f3f4f6;
  border-radius: 4px;
}
.pswd_vis.datepicker {
  z-index: 3;
}
.pswd_vis .form-control {
  background: transparent;
}
.pswd_vis i {
  position: absolute;
  right: 15px;
  top: 14px;
  line-height: 1;
  font-size: 17px;
  color: #6b7280;
  z-index: -1;
}
.pswd_vis .pswd {
  position: absolute;
  right: 0;
  top: 6px;
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  z-index: 1;
}
.pswd_vis .pswd i {
  position: static;
  z-index: 1;
}
.pswd_vis.srch input {
  padding-left: 40px;
}
.pswd_vis.srch i {
  left: 13px;
  right: auto;
}
.pswd_vis.bluslct {
  background: #e4f2ff;
}
.pswd_vis.bluslct .form-control {
  border-color: #e4f2ff;
  color: #3a86ff;
}
.pswd_vis.bluslct i {
  color: #3a86ff;
}

.dashboard-section .card {
  border: none;
}
.dashboard-section .card-body {
  padding: 20px;
}
.dashboard-section .card .card-title {
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
  font-size: 22px;
}

.see-all {
  color: var(--theme-bg);
}

.dashboard-table {
  overflow-x: auto;
}
.dashboard-table th {
  white-space: nowrap;
}
.dashboard-table tr:first-child {
  background: #f3f4f6;
  border: none;
}
.dashboard-table tr {
  border-bottom: 2px dashed #e5e7eb;
}
.dashboard-table th,
.dashboard-table tr td {
  font-weight: 400;
  color: var(--theme-color);
  font-size: 14px;
  padding: 15px 8px;
}
.dashboard-table tr td strong {
  font-weight: 500;
  padding: 4px 0;
}
.dashboard-table tr td span {
  display: block;
  font-size: 13px;
  opacity: 0.7;
  padding: 3px 0;
}
.dashboard-table tr td.action a {
  padding: 0 5px;
  color: #3a86ff;
  text-decoration: underline;
  white-space: nowrap;
}
.dashboard-table tr td.action a:first-child {
  padding-left: 0;
}
.dashboard-table tr td.action a:last-child {
  padding-right: 0;
}
.dashboard-table tr td.action a:hover {
  color: var(--theme-color);
  text-decoration: none;
}
.dashboard-table .cstm_chk {
  padding: 0;
}
.dashboard-table .cstm_chk .checkmark {
  border: 2px solid #9ca3af;
  background: transparent;
  position: static;
  display: block;
  width: 18px;
  height: 18px;
}
.dashboard-table .cstm_chk .checkmark:before {
  left: 4px;
  width: 10px;
  height: 5px;
}
.dashboard-table span.badge {
  display: inline-block;
  padding: 6px 10px !important;
  border-radius: 25px;
  font-weight: 500;
  min-width: 70px;
  text-align: center;
  background: #f3f4f6;
  color: #1f2937;
}
.dashboard-table span.badge.success {
  color: #05a357 !important;
  background: #e5f6eb !important;
}
.dashboard-table span.badge.failed {
  background: #fde7e6 !important;
  color: #e11900 !important;
}
.dashboard-table span.badge.pending {
  background: #ffc1074d !important;
  color: #8a6b0b !important;
}
.dashboard-table span.badge.done {
  color: var(--theme-color) !important;
}
.dashboard-table span.badge.closed {
  color: #0a620c !important;
  background: #e5fbe6 !important;
}
.normal-btns {
  background: #f3f4f6;
  margin-right: 5px;
  border-radius: 4px;
  line-height: 46px;
  padding: 0 24px;
  border: none;
  color: #6b7280;
  font-size: 14px;
}
.normal-btns:hover,
.normal-btns.active {
  background: var(--theme-bg);
  color: #fff;
}
.tbl-actions {
  display: flex;
}
.tbl-actions a {
  line-height: 46px;
  margin-right: 8px;
}
.tbl-actions a:last-child {
  margin-right: 0;
}
.tbl-actions a.cls {
  font-size: 20px;
  margin-left: 5px;
}
.dashboard-table tr td.action .dot-mnu {
  top: 0;
  padding: 0;
  position: relative;
  width: 15px;
}
.dashboard-table tr td.action .dot-mnu .dropdown-menu {
  left: auto !important;
  right: 0 !important;
}
.dot-mnu {
  position: absolute;
  right: 0;
  top: 0;
}
.dot-mnu .dropdown-toggle {
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  position: relative;
  width: 100%;
  text-align: left;
  color: var(--theme-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.dot-mnu .dropdown-toggle:after {
  content: none;
}
.dot-mnu .dropdown-toggle:active {
  background: 0 0;
  color: inherit;
  border: none;
}
.dot-mnu .dropdown-toggle:focus-visible {
  box-shadow: none;
}
.dot-mnu .dropdown-menu {
  /*animation-duration:.3s;-webkit-animation-duration:.3s;animation-fill-mode:both;-webkit-animation-fill-mode:both;-webkit-animation-name:slideIn;animation-name:slideIn;*/
  top: 100%;
  padding: 0;
  width: 180px;
  border-radius: 10px;
  border: none;
  max-height: 365px;
  overflow-y: auto;
  right: 0 !important;
  left: auto !important;
  min-width: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transform: translate(-40px, 33px) !important;
}
.dot-mnu .dropdown-menu li {
  padding: 0 !important;
  border: none !important;
}
.dot-mnu .dropdown-menu li a {
  padding: 8px 12px;
  font-size: 15px;
  border-bottom: 1px solid #f3f4f6;
  display: flex;
  align-items: center;
}
.dot-mnu .dropdown-menu li a:hover {
  background: 0 0;
}
.dot-mnu .dropdown-menu li a.accept:hover {
  color: #12c039;
}
.dot-mnu .dropdown-menu li a.reject:hover {
  color: #e11900;
}
.dot-mnu .dropdown-menu li span {
  width: 25px;
  height: 25px;
  overflow: hidden;
  margin-right: 6px;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-mnu .dropdown-menu li span img {
  width: 100%;
  height: 25px;
  object-fit: cover;
}
.dot-mnu .dropdown-menu li:last-child a {
  border-bottom: none;
}

/* @keyframes slideIn {
	0%{transform:translateY(1rem);opacity:0}
	100%{transform:translateY(0);opacity:1}
	0%{transform:translateY(1rem);opacity:0}
}

@-webkit-keyframes slideIn {
	0%{-webkit-transform:transform;-webkit-opacity:0}
	100%{-webkit-transform:translateY(0);-webkit-opacity:1}
	0%{-webkit-transform:translateY(1rem);-webkit-opacity:0}
} */

.ag-theme-alpine .ag-root-wrapper {
  border: none;
}
.ag-theme-alpine .ag-header {
  border: none;
}
.ag-theme-alpine .ag-row {
  border-bottom: 2px dashed #e5e7eb;
  align-items: center;
  display: flex;
}
.ag-theme-alpine .ag-row .ag-cell,
.ag-theme-alpine .ag-header-row .ag-header-cell {
  font-weight: 400;
  color: var(--theme-color);
  font-size: 14px;
  padding: 11px 8px;
  height: auto;
  font-family: Roboto, sans-serif;
}
.ag-theme-alpine
  .ag-center-cols-container
  .ag-row:last-child
  .actionDot
  .dropdown-menu {
  transform: none !important;
  top: auto !important;
  bottom: 100% !important;
}
.ag-theme-alpine
  .ag-center-cols-container
  .ag-row:first-child
  .actionDot
  .dropdown-menu {
  transform: none !important;
  top: 100% !important;
  bottom: auto !important;
}
.ag-theme-alpine .ag-row .ag-cell span {
  margin: 0;
  line-height: 1;
  opacity: 1;
}
.ag-theme-alpine .ag-row .ag-cell span strong {
  font-weight: 500;
}
.ag-theme-alpine .ag-row .ag-cell span span span {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  opacity: 0.7;
}
.ag-theme-alpine .ag-header-row .ag-header-cell {
  padding: 15px 8px;
  font-weight: 600;
}
.ag-theme-alpine .ag-cell-label-container {
  padding: 0;
}
.ag-checkbox-input-wrapper:focus-within,
.ag-checkbox-input-wrapper:active {
  box-shadow: none;
}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border-color: transparent;
}
/* .ag-theme-alpine .ag-header-cell .ag-header-cell-resize{display:none;}  */
.ag-theme-alpine .ag-paging-panel {
  border: none;
  justify-content: center;
}
.ag-theme-alpine .ag-paging-row-summary-panel {
  display: none;
}
.ag-theme-alpine .ag-paging-page-summary-panel {
  margin: 0;
}
.ag-row .ag-cell.actionDot {
  overflow: visible;
}
.ag-row .ag-cell.actionDot .ag-cell-wrapper {
  display: block;
  width: 40px;
}
.action .dropdown-item.active,
.action .dropdown-item:active,
.actionDot .dropdown-item.active,
.actionDot .dropdown-item:active {
  color: var(--bs-dropdown-link-color);
}
/* .ag-theme-alpine .ag-row .ag-cell.subctrycol{padding-right:0;}
.ag-theme-alpine .ag-row .ag-cell.procol{padding-left:0;} */

.dashboard-section {
  padding: 20px 0 0;
}

.delete-modal {
  max-width: 420px;
}
.delete-modal .modal-content {
  border: none;
}
.delete-modal .modal-body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px;
}
.delete-modal .modal-body i {
  font-size: 45px;
  color: red;
  margin-bottom: 15px;
}
.delete-modal .modal-body .h5 {
  font-size: 20px;
  font-weight: 600;
}
.delete-modal .modal-footer {
  justify-content: space-between;
  padding: 22px;
  border-top: 2px dashed #e5e7eb;
}
.delete-modal .modal-footer button {
  width: 48%;
  margin: 0;
  line-height: 46px;
  padding: 0;
}
.delete-modal .modal-footer .del-btn {
  background: #fde7e6;
  color: red;
}
.select-role .modal-body i {
}
.select-role .modal-body p {
  font-weight: 500;
  font-size: 17px;
}

.breadcrumb ul {
  display: flex;
}
.breadcrumb ul li {
  padding-right: 22px;
  position: relative;
  margin-right: 7px;
  color: var(--theme-color);
}
.breadcrumb ul li a {
  color: #6b7280;
}
.breadcrumb ul li:after {
  content: '\f105';
  font-family: var(--line-icon);
  font-weight: 900;
  right: 0;
  position: absolute;
}
.breadcrumb ul li:last-child:after {
  content: none;
}
.breadcrumb ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.datepicker .dropdown-menu {
  border: none;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 12px;
  max-width: 300px;
}
.datepicker .ngb-dp-header {
  padding: 0;
  background: 0 0;
  margin-bottom: 10px;
}
.datepicker .ngb-dp-header .ngb-dp-arrow {
  width: auto;
}
.datepicker .ngb-dp-header .ngb-dp-arrow button {
  margin: 0;
  border: 1px solid #ddd;
  color: var(--theme-color);
  font-size: 12px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.datepicker .ngb-dp-header .form-select {
  border: none;
  height: 34px;
  margin: 0 5px;
  font-weight: 500;
  padding: 0;
}
.datepicker .ngb-dp-header .form-select:focus {
  box-shadow: none;
}
.datepicker .ngb-dp-week {
  background: 0 0;
  border: none;
  padding: 0 !important;
}
.datepicker .ngb-dp-week .ngb-dp-weekday {
  color: #6c757d;
  font-weight: 500;
  font-style: normal;
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 14px;
}
.datepicker .ngb-dp-week > div {
  width: 40px;
  height: 40px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .datepicker .ngb-dp-week>div>div{border-radius:50px} */
.datepicker .ngb-dp-week .text-muted {
  opacity: 0.7;
}
.datepicker .ngb-dp-week .btn-light:hover {
  background: 0 0;
  border-color: #ddd;
}
.datepicker .ngb-dp-week .active:not(.bg-primary),
.datepicker .ngb-dp-week .bg-primary {
  background: var(--theme-bg);
  border-color: var(--theme-bg);
  color: #fff;
  outline: 0;
  box-shadow: 0 0 15px #c8deff;
}

.custom-modal.modal-dialog {
  max-width: 640px;
}
.custom-modal.modal-lg {
  max-width: 800px;
}
.custom-modal.modal-sm {
  max-width: 440px;
}
.custom-modal .modal-header {
  border-color: #f3f4f7;
  padding: 20px;
}
.custom-modal .modal-header .h5 {
  font-size: 21px;
  font-weight: 500;
}
.custom-modal .btn-close {
  font-size: 14px;
  padding: 5px;
  border: none;
  box-shadow: none;
  right: 5px;
  position: relative;
}
.custom-modal .modal-content {
  border: none;
}
.custom-modal .modal-body {
  padding: 20px;
}
.custom-modal .modal-body .form-group label {
  color: #4b5563;
}
.custom-modal .modal-body .divider {
  border-top: 2px dashed #e5e7eb;
  margin-bottom: 1.5rem;
}
.custom-modal .modal-footer {
  box-shadow: 0px -7px 14px rgba(0, 0, 0, 0.2);
  justify-content: start;
  padding: 15px 20px;
}
.custom-modal .modal-footer button {
  margin: 0;
}
button.cancel,
a.cancel {
  padding: 0 25px;
  border-radius: 4px;
  display: inline-block;
  line-height: 46px;
  color: #f00;
  border: none;
  font-weight: 500;
  background: transparent;
}
button.cancel:hover,
a.cancel:hover {
  color: var(--theme-color);
  background: rgb(255, 228, 228);
}
button.btn-dark {
  line-height: 44px;
  border: none;
  padding: 0 25px;
  font-weight: 500;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
button.btn-light {
  line-height: 44px;
  border: none;
  padding: 0 25px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}

.text-success {
  color: #05a357 !important;
}
.text-danger {
  color: #e11900 !important;
}
.text-panding {
  color: #ffc107 !important;
}

.astik {
  color: #e11900;
}

.step_ .mat-horizontal-stepper-header-container {
  margin-bottom: 25px;
}
.step_ .mat-horizontal-stepper-header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  width: calc(100% / 7);
}
.step_ .mat-horizontal-stepper-header .mat-step-label {
  color: #9ca3af;
}
.step_ .mat-horizontal-stepper-header .mat-step-label.mat-step-label-selected {
  color: var(--theme-color);
}
.step_ .mat-horizontal-stepper-header .mat-step-icon {
  margin: 0;
  background: #fff;
  width: 100%;
  height: 18px;
  border-radius: 0;
}
.step_ .mat-horizontal-stepper-header .mat-step-icon .mat-step-icon-content {
  margin: 0;
  background: #fff;
  border: 2px solid #d1d5db;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 1;
}
.step_
  .mat-horizontal-stepper-header
  .mat-step-icon-selected
  .mat-step-icon-content {
  border-color: #05a357;
}
.step_
  .mat-horizontal-stepper-header
  .mat-step-icon-state-edit
  .mat-step-icon-content {
  border-color: #0a864a;
  background: #05a357;
}
.mat-step-icon:before {
  content: '';
  width: 100%;
  border-bottom: 2px dashed #e5e7eb;
  left: 0;
  top: calc(50% - 1px);
  position: absolute;
  z-index: 0;
  height: 2px;
}
.step_ .mat-horizontal-stepper-header .mat-step-icon-selected:before,
.step_ .mat-horizontal-stepper-header .mat-step-icon-state-edit:before {
  border-bottom-style: solid;
  border-color: #05a357;
}
.step_
  .mat-horizontal-stepper-header
  .mat-step-icon-state-edit
  .mat-step-icon-content:before {
  content: '\F633';
  font-family: var(--bootstrap-icon);
  line-height: 1;
}
.step_ .mat-horizontal-content-container {
  padding: 10px 0;
}
.step_ .mat-step-header.cdk-keyboard-focused,
.step_ .mat-step-header.cdk-program-focused,
.step_ .mat-step-header.cdk-mouse-focused,
.step_ .mat-step-header.cdk-focused,
.step_ .mat-step-header:hover:not([aria-disabled]),
.step_ .mat-step-header:hover[aria-disabled='false'] {
  background: transparent;
  text-decoration: none;
}
.step_ .mat-step-header .mat-step-header-ripple,
.step_ .mat-stepper-horizontal-line,
.step_ .mat-horizontal-stepper-header .mat-step-icon span,
.step_ .mat-step-icon .mat-icon {
  display: none;
}

/********** Customs Checkbox / Radio / File **********/
.cstm_chk {
  position: relative;
  padding-left: 30px;
  line-height: 20px !important;
  color: var(--theme-color);
}
.cstm_chk input,
app-select-all-checkbox input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.cstm_chk .checkmark,
app-select-all-checkbox .checkmark {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #6b7280;
  border-radius: 3px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}
.cstm_chk .checkmark:before,
app-select-all-checkbox .checkmark:before {
  content: '';
  width: 12px;
  height: 6px;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  position: absolute;
  left: 3px;
  top: 5px;
  transform: rotate(-45deg);
  transition: all ease-in-out 0.4s;
}
.cstm_chk input:checked ~ .checkmark,
app-select-all-checkbox input:checked ~ .checkmark {
  border-color: var(--theme-bg);
  background: var(--theme-bg);
}
.cstm_chk input:checked ~ .checkmark:before,
app-select-all-checkbox input:checked ~ .checkmark:before {
  border-color: #fff;
}
.cstm_chk.cstm_rdo .checkmark {
  border-radius: 35px;
  border: 2px solid #6b7280;
}
.cstm_chk.cstm_rdo .checkmark:before {
  width: 12px;
  height: 12px;
  border: none;
  background: transparent;
  border-radius: 35px;
  left: 2px;
  top: 2px;
  transform: none;
}
.cstm_chk.cstm_rdo input:checked ~ .checkmark {
  border-color: var(--theme-bg);
  background: transparent;
}
.cstm_chk.cstm_rdo input:checked ~ .checkmark:before {
  background: var(--theme-bg);
}

app-select-all-checkbox {
  position: relative;
  width: 16px;
  height: 16px;
}
app-select-all-checkbox .checkmark {
  width: 16px;
  height: 16px;
  border: 2px solid #999;
  border-radius: 0;
}
app-select-all-checkbox .checkmark:before {
  left: 1px;
  top: 3px;
  width: 10px;
  height: 5px;
}

/* .ctsm_file{border:1px dashed #d1d5db;background:#f3f4f6;border-radius:4px;padding:20px;position:relative;display:flex;flex-wrap:wrap}
.ctsm_file input{width:100%;height:100%;position:absolute;left:0;top:0;opacity:0}
.ctsm_file i{width:46px;height:46px;background:#d1d5db;display:flex;align-items:center;justify-content:center;border-radius:4px;font-size:25px;color:#fff}
.ctsm_file span{width:60%;padding-left:20px} */
.form-group .file,
.form-group .file-two,
.form-group .file-three {
  height: 46px;
  border-radius: 4px;
  background: #f3f4f6;
  color: #6b7280;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d1d5db;
}
.form-group .file > input,
.form-group .file-two > input,
.form-group .file-three > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0px;
  opacity: 0;
}
.form-group .file span a {
  color: #3a86ff;
  text-decoration: underline;
}
.form-group .file-two {
  justify-content: start;
  height: auto;
  padding: 15px;
}
.form-group .file-two i {
  width: 46px;
  height: 46px;
  margin-right: 10px;
  border-radius: 4px;
  background: #d1d5db;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
}
.form-group .file-two span {
  width: 275px;
}
.form-group .file-three {
  height: auto;
  flex-direction: column;
  text-align: center;
  padding: 50px 0;
}
.form-group .file-three i {
  font-size: 60px;
  line-height: 1;
}
.form-group .file-three span {
  line-height: 32px;
  font-size: 17px;
  margin-top: 10px;
}
.form-group .file-three span span {
  color: #3a86ff;
  font-weight: 500;
}

.show-files {
  position: relative;
  display: flex;
  margin: 10px 0 15px;
}
.show-files .bx {
  width: 70px;
  height: 70px;
  position: relative;
  margin-right: 15px;
}
.show-files .bx figure {
  border-radius: 7px;
  overflow: hidden;
  background: #f9f9f9;
  width: 100%;
  height: 70px;
  border: 2px solid #ccc;
}
.show-files .bx figure img {
  height: 70px;
}
.show-files .bx figure a {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.show-files .bx figure i {
  font-size: 40px;
  line-height: 1;
}
.show-files .bx figure i.bi-file-pdf {
  color: #d52828;
}
.show-files .bx a {
  position: absolute;
  right: -6px;
  top: -8px;
}

.step_info_cnt .step_inf .bnfts {
  padding-top: 10px;
}
.step_info_cnt .step_inf .bnfts li,
.compare_bx .prt.ftrs li {
  list-style-type: initial;
  padding: 5px 0;
}
.step_info_cnt .step_inf .bnfts ul,
.step_info_cnt .step_inf .bnfts ol,
.step_info_cnt .step_inf .bnfts dl,
.compare_bx .prt.ftrs ul,
.compare_bx .prt.ftrs ol,
.compare_bx .prt.ftrs dl {
  margin-left: 17px;
  padding: 0;
}
.step_info_cnt .step_inf .bnfts ol li,
.compare_bx .prt.ftrs ol li {
  list-style-type: decimal;
}
.step_info_cnt .step_inf .bnfts li:before,
.compare_bx .prt.ftrs li:before {
  content: none;
}
div.limit > div,
div.limit p {
  margin-bottom: 10px;
}
div.limit > div:last-child,
div.limit p:last-child {
  margin-bottom: 0;
}
/* .compare_bx .prt.ftrs li:marker{} */

.aside-navbar .accordion .accordion-item {
  background: none;
  border: none;
  border-radius: 0;
}
.aside-navbar .accordion-item .accordion-button.collapsed {
  border-radius: 8px;
  background: transparent;
}
.aside-navbar .accordion-item .accordion-button.collapsed:hover {
  background: rgba(0, 0, 0, 0.2);
}
.aside-navbar .accordion-item .accordion-button {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0;
  padding: 0.8rem 0.8rem 0.8rem 1rem;
  color: #fff;
  font-size: 15px;
  box-shadow: none;
  border-radius: 8px 8px 0 0;
}
.aside-navbar .accordion-item .accordion-button:after {
  background: none;
  content: '\F282';
  font-family: var(--bootstrap-icon);
}
.aside-navbar .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.aside-navbar .tsk .accordion-item .accordion-button:before {
  content: '\f4e8';
  font-family: var(--bootstrap-icon);
  width: 27px;
}
.aside-navbar .accordion-item .collapse,
.aside-navbar .accordion-item .collapsing {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
}
.aside-navbar .accordion-item .accordion-body {
  padding: 0;
}
.aside-navbar .accordion-body ul {
  padding: 0 10px 10px 27px !important;
}
.aside-navbar .accordion-body ul a {
  color: #e5e7eb !important;
}
.aside-navbar .accordion-body ul a:hover,
.aside-navbar .accordion-body ul a:focus {
  background: transparent !important;
  color: #fff !important;
}

.no-result {
  text-align: center;
  padding: 100px 0;
  color: var(--theme-color);
  font-weight: 500;
}
.no-result i {
  font-size: 42px;
  display: block;
  line-height: 1;
  margin-bottom: 23px;
  opacity: 0.6;
}

/*******Mat Select*******/
.mat-mdc-form-field-type-mat-select {
  width: 100%;
}
.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-infix {
  width: 100%;
  padding: 10px 0;
  min-height: 46px;
}
.mat-mdc-form-field-type-mat-select
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding: 11px 0;
}
.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field-type-mat-select
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: white;
}
.mat-mdc-form-field-type-mat-select .mdc-line-ripple:before,
.mat-mdc-form-field-type-mat-select .mdc-line-ripple:after {
  border: none;
}
.mat-mdc-form-field-type-mat-select .mdc-text-field--filled {
  border-radius: 4px;
  background: white;
}
.mat-mdc-form-field-type-mat-select .mat-mdc-select-value-text {
  color: #6b7280;
}
.mat-mdc-form-field-type-mat-select .mat-mdc-select-arrow-wrapper {
  position: relative;
}
.mat-mdc-form-field-type-mat-select .mat-mdc-select-arrow {
  display: none;
}
.mat-mdc-form-field-type-mat-select .mat-mdc-select-arrow-wrapper:before {
  content: '\F282';
  font-family: var(--bootstrap-icon);
}

tag-input .ng2-tag-input {
  border: 1px solid #f3f4f6 !important;
  background: white;
  border-radius: 4px;
}
tag-input .ng2-tag-input__text-input {
  background: transparent;
}
.ng2-tags-container {
  padding-left: 10px;
}
.ng2-tags-container .ng-trigger {
  background: #e5e7eb;
  border-radius: 4px;
  line-height: 30px;
  font-size: 13px;
}
.ng2-tags-container
  .ng-trigger:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  box-shadow: none;
  background: #e5e7eb;
}
.ng-trigger delete-icon svg {
  height: 30px !important;
}
.ng-trigger delete-icon:hover {
  transform: none !important;
}
.btn-light {
  background: #e1e1e1;
}

.mat-date .mat-mdc-form-field-focus-overlay {
  background: none;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  background: #f3f4f6;
  border-radius: 4px;
  height: 46px;
  padding-left: 12px;
}
.mat-date .mat-mdc-form-field-bottom-align {
  display: none;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding: 12px 0;
  min-height: 46px;
}
.mdc-line-ripple {
  display: none;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  width: 36px;
  height: 36px;
  padding: 0;
}
.mat-date .mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
  width: 20px;
  height: 20px;
}
.mat-date .mat-mdc-form-field-icon-suffix,
.mat-date [dir='rtl'] .mat-mdc-form-field-icon-prefix {
  padding: 0 4px;
}

.chat_list .ng-scroll-content {
  width: 100%;
}

.cdk-overlay-container {
  z-index: 10001;
}

.input-container {
  height: 46px !important;
  border-radius: 4px;
  background: #f3f4f6;
  border: transparent !important;
  padding-right: 0 !important;
}
.input-container .phoneInput {
  width: calc(100% - 60px);
  background: 0 0;
  border: none;
  height: 46px;
  border-radius: 0 4px 4px 0;
}
.input-container .dropdown__items {
  border: none !important;
  padding: 10px !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px !important;
}
.input-container .searchCountry {
  margin-bottom: 10px !important;
}
.input-container .searchCountry .searchCountry__input {
  background: #f3f3f3;
  border-color: #eee;
  height: 42px;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 12px;
}
.input-container .dropdown__item {
  min-height: auto !important;
  margin-bottom: 3px;
  border: none !important;
}
.input-container .dropdown__item span {
  font-size: 13px;
}
.input-container .dropdown__item:hover {
  transform: none !important;
}

.mat-mdc-form-field {
  width: 100%;
}
.phone-code-input .mat-mdc-text-field-wrapper {
  padding: 0;
  background: #f3f4f6;
}
.phone-code-input
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mat-mdc-form-field-infix {
  padding: 13px 10px;
  min-height: auto;
}
.phone-code-input .mat-mdc-form-field-bottom-align {
  display: none;
}
.phone-code-input .country-selector {
  opacity: 1 !important;
  width: auto !important;
  padding: 0 22px 0 13px !important;
}
.phone-code-input .country-selector .mdc-button__ripple:before {
  content: none;
}
/* .mat-mdc-menu-trigger.country-selector.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base{background-color:transparent !important;} */
.phone-code-input .mdc-notched-outline__leading {
  border: 1px solid transparent !important;
  border-right: none !important;
}
.mdc-notched-outline__trailing {
  border: 1px solid transparent !important;
  border-left: none !important;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--invalid .mdc-notched-outline__leading {
  border: 1px solid #dc3545 !important;
  border-right: none !important;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing,
.mdc-text-field--invalid .mdc-notched-outline__trailing {
  border: 1px solid #dc3545 !important;
  border-left: none !important;
}
.cdk-overlay-pane {
  max-height: 300px !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox {
  padding: 0;
  margin-right: 10px;
}
.mat-mdc-checkbox.mat-accent .mat-mdc-checkbox-touch-target {
  position: static;
  width: 18px;
  height: 18px;
  transform: none;
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox .mdc-checkbox__background {
  left: 0;
  top: 0;
}
.mat-mdc-checkbox.mat-accent
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: #3f51b5 !important;
  background-color: #3f51b5 !important;
}

.signup-page .form-control.ng-valid,
.signup-page .phone-code-input .ng-valid .mat-mdc-text-field-wrapper {
  background: #e9f0ff;
  border-color: #d1d9eb;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media screen and (min-width: 1300px) {
  .container {
    max-width: 1250px;
  }
}

@media screen and (max-width: 1299px) {
  .display-1 {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    max-width: 980px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 750px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 560px;
  }
}

@media screen and (max-width: 575px) {
  .container {
    max-width: 100%;
  }
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 399px) {
}
