/* You can add global styles to this file, and also import other style files */
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css'; /* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
.clickable {
  cursor: pointer;
}
/*File upload styling*/
.file-input-wrapper {
  position: relative;
  width: 100%;
  padding: 0 0.75rem;
}

.file-input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.custom-file-input {
  width: 100%;
  height: 100px;
  border: 2px dashed #000209;
  background-color: #eff1f2;
  text-align: center;
  line-height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.custom-file-input label {
  font-size: 14px;
  color: #bfc0c2;
}

.upload-icon {
  opacity: 0.5;
  vertical-align: middle;
  margin-right: 5px;
}

.file-badge {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 16px;
  background-color: #eff1f2;
  align-self: start;
  margin-left: 0.5rem;
}
.file-badge .remove {
  cursor: pointer;
  margin-left: 1rem;
  background-color: rgb(128, 128, 128);
  padding: 0 0.4rem;
  border-radius: 100%;
  color: white;
}
.custom-select-panel {
  height: 100% !important;
  max-height: unset !important;
  display: flex !important;
  flex-wrap: wrap !important;
  height: 100% !important;
}
.custom-select-panel mat-option {
  flex-basis: 30% !important;
}
.mat-select-no-arrow .mat-mdc-select-arrow-wrapper {
  display: none;
}
.invalid,
.required {
  color: red;
}
.ngx-mat-tel-input-container {
  position: relative;
  background: #f3f4f6;
  border-radius: 5px;
  height: 46px;
}
input:not(.country-search):not([type='checkbox']):not([type='radio']):not(
    [type='file']
  ) {
  height: 46px;
}
.country-selector {
  opacity: 1 !important;
}
.compound-cell > span {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.compound-cell > span > span {
  font-size: 13px;
  opacity: 0.7;
}
